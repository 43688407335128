
import Vue from 'vue'
import Component from 'vue-class-component'
import { DocumentUpload, UploadOptions } from '@/presentation/affiliate/DocumentUpload'
import MemberInfo from '@/models/MemberInfo'
import { AffiliatedDocumentType } from '@/enums/AffiliatedDocumentType'
import { CorpAll, CorpStatus } from '@/gateway/affiliate/model/CorpAll'
import { GetCorpAll } from '@/gateway/affiliate/GetCorpAll'
import { Getter, Mutation } from 'vuex-class'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component
export default class Upload extends Vue {
  @Getter getCorpAllState: any
  @Mutation setCorpAllState: any

  isReady = false
  documentUpload!: DocumentUpload
  memberInfo!: MemberInfo
  forms: Array<UploadOptions> = []
  originalFile: Dictionary<boolean> = {}
  corpAll!: CorpAll
  confirmModal: boolean = false

  isDisplayUploadAlert () {
    const setTime = localStorage.getItem('affiliate_upload_alert')
    const prevTime = Number(setTime)
    const now = new Date()
    const currentTime = new Date(now.setDate(now.getDate() - 1))
    return prevTime < currentTime.getTime()
  }

  onToggleUploadAlert (value: boolean) {
    if (value) localStorage.setItem('affiliate_upload_alert', new Date().getTime().toString())
    else localStorage.removeItem('affiliate_upload_alert')
  }

  onClickSubButton () {
    this.corpAll.status = CorpStatus.FORM_COMPLETE
    this.setCorpAllState(this.corpAll)
    this.$router.push('documents')
  }

  onClickMoveDownload () {
    this.$router.push('download')
  }

  async onClickSubmit () {
    const haveAllDocument = this.documentUpload.haveAllDocument()
    if (!haveAllDocument) {
      await this.$alert('업로드 되지 않은 문서가 있습니다.', { center: true })
      return
    }
    const message = '모든 서류를 첨부하셨나요?<br>제휴신청을 완료하시겠습니까?'
    const confirm: boolean = await this.$confirm(message, {
      confirmButtonText: '서류 제출하기',
      cancelButtonText: '취소',
      dangerouslyUseHTMLString: true,
      center: true
    }).then(() => true).catch(() => false)
    if (!confirm) return
    await this.documentUpload.onClickSubmit()
    await this.$router.push('account')
  }

  uploadButtonType (type: AffiliatedDocumentType): string {
    return this.showUploadedFile(type) ? 'info' : 'primary'
  }

  showUploadedFile (type: AffiliatedDocumentType): boolean {
    if (this.originalFile[type] === false) return false
    const fileName = this.documentUpload.getFileName(type)
    if (fileName) this.originalFile[type] = true
    return !!this.originalFile[type]
  }

  private setMemberInfo () {
    if (!MemberInfoGateway.hasAuthInfo()) {
      this.$router.replace('/signUp')
      return
    }
    this.memberInfo = MemberInfoGateway.getMemberInfo()
  }

  async upload (files: any, type: AffiliatedDocumentType): Promise<void> {
    const invalidFile = !(files && files.raw)
    if (invalidFile) return
    try {
      const file = files.raw
      const fileName = files.name
      await this.documentUpload.upload(file, type, fileName)
    } catch (error) {
      this.$message({ message: error.message, type: 'error' })
      return
    }
    this.forms = await this.documentUpload.getForms()
    this.isReady = await this.documentUpload.initialize()
    if (this.isDisplayUploadAlert()) this.confirmModal = true
    this.originalFile = Object.assign({}, this.originalFile)
  }

  private async setCorpAll (): Promise<void> {
    this.corpAll = this.getCorpAllState
    const hasCorpAllValue: boolean = !!Object.keys(this.corpAll).length
    if (hasCorpAllValue) return
    const corpId = this.memberInfo.corp_id
    this.corpAll = await GetCorpAll.getInstance().request({ corp_id: corpId })
    this.setCorpAllState(this.corpAll)
  }

  async created () {
    this.setMemberInfo()
    await this.setCorpAll()
    const isProcessing = this.corpAll.status === Number(CorpStatus.PROCESSING)
    if (isProcessing) await this.$router.replace('account')
    const isRegistered = Number(this.corpAll.status) < Number(CorpStatus.FORM_COMPLETE)
    if (isRegistered) {
      this.$message({ message: '문서작성을 먼저 완료해 주세요', type: 'warning' })
      return this.$router.replace('documents')
    }
    this.documentUpload = new DocumentUpload(this.memberInfo)
    this.forms = await this.documentUpload.getForms()
    this.isReady = await this.documentUpload.initialize()
  }
}
