import { AffiliatedDocumentType } from '@/enums/AffiliatedDocumentType'
import MemberInfo from '@/models/MemberInfo'
import { GetSignUpDocuments } from '@/gateway/affiliate/GetSignUpDocuments'
import { SignUpDocumentFile, SignUpDocuments } from '@/gateway/affiliate/SignUpDocuments'
import { SetSignUpDocuments } from '@/gateway/affiliate/SetSignUpDocuments'
import { FileTransferHandler } from '@/lib/FileTransferHandler'
import { NotifySignUpDocuments } from '@/gateway/affiliate/NotifySignUpDocuments'
import { GetCorpAll } from '@/gateway/affiliate/GetCorpAll'
import { CorpAll } from '@/gateway/affiliate/model/CorpAll'
import { BusinessCategory } from '@/presentation/affiliate/CompanyInfoField'

type UploadOption = {
  label: string;
  type: AffiliatedDocumentType;
}
export type UploadOptions = {
  option: Array<UploadOption>;
}

export interface DocumentUpload {
  initialize (): Promise<boolean>;

  formClass: string;
  disabledSubmit: boolean;

  haveAllDocument (): boolean;
  getForms (): Promise<Array<UploadOptions>>;
  upload (file: any, type: AffiliatedDocumentType, fileName: string): Promise<void>;
  downloadFile (type: AffiliatedDocumentType): void;
  getFileName (type: AffiliatedDocumentType): string;

  subButtonLabel: string;
  submitLabel: string;
  onClickSubmit (): void;
}

export class DocumentUpload implements DocumentUpload {
  private readonly memberInfo: MemberInfo
  constructor (memberInfo: MemberInfo) {
    this.memberInfo = memberInfo
  }
  async initialize (): Promise<boolean> {
    await this.setSignUpDocuments()
    return true
  }

  fileTransferHandler: FileTransferHandler = FileTransferHandler.getInstance()
  signUpDocumentsMap: Map<AffiliatedDocumentType, SignUpDocumentFile> = new Map<AffiliatedDocumentType, SignUpDocumentFile>()

  formClass = 'divide-form'
  private essentialDocuments: UploadOptions = {
    option: [
      { label: '이용약정서', type: AffiliatedDocumentType.USAGE_AGREEMENT },
      { label: '고객거래 확인서', type: AffiliatedDocumentType.TRADE_CONFIRMATION },
      { label: '개인정보등 활용 필수적 동의서', type: AffiliatedDocumentType.PRIVACY_AGREEMENT }
    ]
  }
  private corperationDocuments: Array<UploadOption> = [
    { label: '정산 받으실 입금계좌 (법인명의만 가능)', type: AffiliatedDocumentType.CORP_ACCOUNT },
    { label: '법인등기부등본(3개월 이내 발급분)', type: AffiliatedDocumentType.CORP_CERTIFIED },
    // { label: '법인인감증명서(3개월이내 발급분)', type: AffiliatedDocumentType.CORP_SIGNATURE },
    { label: '주주명부 (인감날인)', type: AffiliatedDocumentType.CORP_SHAREHOLDERS }
  ]
  private entrepreneurDocuments: Array<UploadOption> = [
    { label: '정산 받으실 입금계좌(대표자명의만 가능)', type: AffiliatedDocumentType.ENTRE_ACCOUNT },
    // { label: '개인 인감증명서(3개월 이내 발급분)', type: AffiliatedDocumentType.ENTRE_SIGNATURE }
  ]

  subButtonLabel = '문서 작성으로 돌아가기'
  moveDownloadLabel = '문서 다운로드하기'
  disabledSubmit = false
  submitLabel = '서류 신청하기'
  forms: Array<UploadOptions> = []
  requiredDocuments: Array<AffiliatedDocumentType> = []

  async getForms (): Promise<Array<UploadOptions>> {
    const param = { corp_id: this.memberInfo.corp_id }
    const corpAll: CorpAll = await GetCorpAll.getInstance().request(param)
    const isEntrepreneur = Number(corpAll.biz_category) === Number(BusinessCategory.ENTREPRENEUR)
    const categoryDocuments: Array<UploadOption> = isEntrepreneur ? this.entrepreneurDocuments : this.corperationDocuments
    this.forms = [
      this.essentialDocuments,
      {
        option: [
          { label: '사업자 등록증', type: AffiliatedDocumentType.BUSINESS_REGISTRATION },
          { label: '대표자 신분증(공동대표 모두 필요)', type: AffiliatedDocumentType.OWNER_ID_CARD },
          { label: '인보이스, 계약서 등 경상거래를 입증하는 자료(실제 결제 진행시 필요한 자료)', type: AffiliatedDocumentType.INVOICE_CONTRACT },
          ...categoryDocuments
        ]
      }
    ]
    this.forms.forEach(form => {
      const types: Array<AffiliatedDocumentType> = form.option.map(option => option.type)
      this.requiredDocuments.push(...types)
    })
    return this.forms
  }

  private async setSignUpDocuments (): Promise<void> {
    const param = { corp_id: this.memberInfo.corp_id }
    const response = await GetSignUpDocuments.getInstance().request(param)
    const documents: Array<SignUpDocumentFile> = Array(...response.list)
    documents.forEach(doc => {
      this.signUpDocumentsMap.set(doc.type, doc)
    })
  }

  private getDocument (type: AffiliatedDocumentType): SignUpDocumentFile | undefined {
    return this.signUpDocumentsMap.get(type)
  }

  haveAllDocument (): boolean {
    return this.requiredDocuments.every(type => {
      return this.signUpDocumentsMap.has(type)
    })
  }

  getFileName (type: AffiliatedDocumentType): string {
    const document = this.getDocument(type)
    return document?.file_name || ''
  }

  async upload (file: any, type: AffiliatedDocumentType, fileName: string): Promise<void> {
    const corpId = this.memberInfo.corp_id
    const document = this.getDocument(type)
    const base64File = await this.fileTransferHandler.blobToBase64(file)
    if (!base64File || typeof base64File !== 'string') return
    const signUpDocumentFile: SignUpDocumentFile = {
      id: document?.id || '0',
      corp_id: corpId,
      type: type,
      file_name: fileName,
      file: base64File
    }
    const signUpDocumentsParams: SignUpDocuments = {
      corp_id: corpId,
      list: [signUpDocumentFile]
    }
    const response = await SetSignUpDocuments.getInstance().request(signUpDocumentsParams)
    if (response.code === 200) {
      this.signUpDocumentsMap.set(type, signUpDocumentFile)
    }
  }

  downloadFile (type: AffiliatedDocumentType): void {
    const document = this.getDocument(type)
    if (!document || !document.file) return
    const blobUrl: string = this.fileTransferHandler.getBase64ObjectUrl(document.file)
    if (!blobUrl) return
    this.fileTransferHandler.download(blobUrl, document.file_name)
  }

  async onClickSubmit () {
    const param = { corp_id: this.memberInfo.corp_id }
    await NotifySignUpDocuments.getInstance().request(param)
  }
}
