import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { SignUpDocuments } from '@/gateway/affiliate/SignUpDocuments'

export interface GetSignUpDocuments extends IApiRequest {
  getInstance (): GetSignUpDocuments;
  request (params: { corp_id: string }): Promise<SignUpDocuments>;
}

export class GetSignUpDocuments implements GetSignUpDocuments {
  private static instance: GetSignUpDocuments
  private constructor () {}
  public static getInstance (): GetSignUpDocuments {
    if (!this.instance) this.instance = new GetSignUpDocuments()
    return this.instance
  }

  async request (params: { corp_id: string }): Promise<SignUpDocuments> {
    const requestOption: RequestOption = {
      url: 'corp/documents',
      method: 'get',
      params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response?.data
  }
}
