import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'

export interface NotifySignUpDocuments extends IApiRequest {
  getInstance (): NotifySignUpDocuments;
  request (data: { corp_id: string }): Promise<Dictionary<any>>;
}

export class NotifySignUpDocuments implements NotifySignUpDocuments {
  private static instance: NotifySignUpDocuments
  private constructor () {}
  public static getInstance (): NotifySignUpDocuments {
    if (!this.instance) this.instance = new NotifySignUpDocuments()
    return this.instance
  }

  async request (data: { corp_id: string }): Promise<Dictionary<any>> {
    const requestOption: RequestOption = {
      url: 'notify',
      method: 'post',
      data
    }
    const response: any = await sentbizApi.request(requestOption)
    return response?.data
  }
}
